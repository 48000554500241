import $ from 'jquery';
import flickity from 'flickity';

(function($) {
    // Lazyload lozad.js
    $("img").each(function () {
        // voegt class 'lozad' toe aan alle img's voor Lazyload behalve img's in een carousel
        $(this).not('.carousel img').not('.no-lozad').addClass('lozad');
        $(this).not('.carousel img').not('.no-lozad').attr("data-src", $(this).attr("src"));
        $(this).not('.carousel img').not('.no-lozad').removeAttr("src");
    });
    // lazy loads elements with default selector as '.lozad'
    const observer = lozad();
    observer.observe();

    // Sliders
    $(".carousel").each(function() {
        var carousel = $(this),
            carouselOptions = {
                arrowShape: 'M50,-2.13162821e-14 L12.430944,37.9571547 C7.85635201,42.5790034 7.85635201,50.0710143 12.430944,54.6928631 C17.0055359,59.3147118 24.4209436,59.3147118 28.9955356,54.6928631 L38.2852959,45.3071369 L38.2852959,88.1642798 C38.2852959,94.6975973 43.527626,100 50,100 C56.4665167,100 61.7147041,94.6975973 61.7147041,88.1642798 L61.7147041,45.3071369 L71.0044644,54.6928631 C73.2946891,57.0067464 76.287796,58.1607291 79.2867602,58.1607291 C82.2857245,58.1607291 85.2788314,57.0067464 87.569056,54.6928631 C92.143648,50.0710143 92.143648,42.5790034 87.569056,37.9571547 L50,-2.13162821e-14 Z',
                cellSelector: ".carousel__slide",
                imagesLoaded: true,
                lazyLoad: true,
                cellAlign: "left",
                wrapAround: true,
                pageDots: false
            };

    if (carousel.hasClass("carousel-slider")) {
        $.extend(true, carouselOptions, {
            cellAlign: 'left',
            contain: true,
            pageDots: false,
            imagesLoaded: true
        });
    }

    if (carousel.hasClass("carousel--text")) {
        $.extend(true, carouselOptions, {
            pageDots: false,
            imagesLoaded: true
        });
    }

    if (carousel.hasClass("carousel--photos")) {
        $.extend(true, carouselOptions, {
            arrowShape: 'M50,-2.13162821e-14 L12.430944,37.9571547 C7.85635201,42.5790034 7.85635201,50.0710143 12.430944,54.6928631 C17.0055359,59.3147118 24.4209436,59.3147118 28.9955356,54.6928631 L38.2852959,45.3071369 L38.2852959,88.1642798 C38.2852959,94.6975973 43.527626,100 50,100 C56.4665167,100 61.7147041,94.6975973 61.7147041,88.1642798 L61.7147041,45.3071369 L71.0044644,54.6928631 C73.2946891,57.0067464 76.287796,58.1607291 79.2867602,58.1607291 C82.2857245,58.1607291 85.2788314,57.0067464 87.569056,54.6928631 C92.143648,50.0710143 92.143648,42.5790034 87.569056,37.9571547 L50,-2.13162821e-14 Z',
            cellSelector: ".carousel__slide",
            imagesLoaded: true,
            lazyLoad: true,
            cellAlign: "center",
            wrapAround: true,
            pageDots: false
        });
    }

    var flkty = new flickity(this, carouselOptions);

});

    $( ".show-menu" ).click(function(event) {
        event.preventDefault();
        $('.main-header__menu').toggleClass('active');
        $(this).toggleClass('open');
        $('.main-header').toggleClass('is-open');
    });

    $( ".menu-item-has-children" ).click(function(event) {
        $(".menu-item-has-children > a").removeAttr("href");
        if($(this).hasClass('is-open')) {
            console.log('has class');
            $(this).removeClass('is-open');
        } else {
            console.log('no class');
            $( ".menu-item-has-children" ).removeClass('is-open');
            $(this).addClass('is-open');
        }
    });

    // var $document = $(document),
    // $element = $('.logo__image'),
    // className = 'hasScrolled';

    // $document.scroll(function() {
    // if ($document.scrollTop() >= 50) {
    //     $element.addClass(className);
    // } else {
    //     $element.removeClass(className);
    // }
    // });


    // check if link in nav is a phone number or mail, if so add a class
    $(".main-header__meta a").each(function() {
        if (this.href.indexOf('tel') !== -1) {
            $(this).addClass('icon-tel');
        }
        if (this.href.indexOf('mailto') !== -1) {
            $(this).addClass('icon-mail');
        }
    });

    if ($('#site-content').data('id')) {
        $('.your-subject input').val($('#site-content').data('id'));
        $('.your-subject input').attr('disabled','disabled');
        $('.your-subject input').addClass('disabled');
      }

    $('.main-header ul.sub-menu').siblings('a').addClass('has-sub-menu');

})(jQuery);
